import classNames from 'clsx';
import compact from 'lodash/compact';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ClampedText from 'react-dotdotdot';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { unifiedEvents } from 'src/client/api';
import { setBreadcrumbId } from 'src/common/actions/reporting';
import useActions from 'src/common/hooks/useActions';
import { selectIsUnifiedEventsContentReportingEnabled } from 'src/common/selectors/config';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import cssVariables from 'src/common/styles/variables';
import { doesContainerSupportContentReporting } from 'src/common/utils/guideItem/doesContainerSupportContentReporting';
import { getDestInfoUrlObj } from 'src/common/utils/guideItem/getGuideItemPathname';
import { behaviors as GuideItemBehaviors } from 'src/common/utils/guideItemTypes';
import { closeNowPlayingDialog } from '../../../actions/dialog';
import { SEE_ALL } from '../../../constants/localizations/containerItems';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import commonCss from '../../../styles/common.module.scss';
import getGuideItemProducts from '../../../utils/guideItem/getGuideItemProducts';
import { Badge } from '../../shared/Badge';
import chevronCss from '../../shared/link/chevron-link.module.scss';
import PremiumLogo from '../../subscription/icons/PremiumLogo';
import css from './container-title.module.scss';

const HEADERLESS_LIST = 'HeaderlessList';

const ContainerTitle = ({
  containerItem,
  getStyles,
  className,
  containerTitle,
}) => {
  const {
    containerNavigation = {},
    presentation = {},
    style = '',
    subtitle,
  } = containerItem;

  const title = containerTitle || containerItem.title;
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const isUnifiedEventsContentReportingEnabled = useSelector(
    selectIsUnifiedEventsContentReportingEnabled,
  );
  const breadcrumbId = useSelector(selectBreadcrumbId);
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);
  const actions = useActions({
    closeNowPlayingDialog,
    setBreadcrumbId,
  });

  const {
    titleBadge,
    titleBadgeColor = cssVariables['--premium-gold-light'],
    titleBadgeTextColor = cssVariables['--white'],
    titleVisible,
    titleSize,
    subtitleVisible,
  } = getStyles(style);

  const hideTitle = containerTitle
    ? false
    : presentation.layout === HEADERLESS_LIST ||
      titleVisible === false ||
      !title;
  const hideSubtitle = subtitleVisible === false || !subtitle;

  if (hideTitle) {
    return null;
  }

  const guideItem = containerItem?.children?.[0] || {};
  const {
    guideId = '',
    image: guideImage = '',
    title: guideTitle = '',
  } = guideItem;
  const guideProducts = compact(
    getGuideItemProducts(containerItem?.children?.[0]),
  );

  const hasMoreItems = containerNavigation.type === GuideItemBehaviors.browse;
  let linkTo;

  if (hasMoreItems) {
    linkTo = {
      ...getDestInfoUrlObj(containerNavigation.destinationInfo),
      state: { itemToken: get(containerNavigation, 'context.itemToken') },
    };
  }

  const containerTitleContainerClassNames = classNames(
    css.containerTitleContainer,
    {
      [css.spaceBetween]: hasMoreItems,
    },
    className,
  );

  const titleHeader = (
    <div className={css.titleContent} data-testid="titleContent">
      <ClampedText
        className={classNames(css.titleHeader, {
          [css[`titleHeader${titleSize}`]]: titleSize,
        })}
        clamp={2}
      >
        {title}
      </ClampedText>
      {!!titleBadge && (
        <Badge
          dataTestId="titleBadge"
          className={css.titleBadge}
          style={{
            backgroundColor: titleBadgeColor,
            color: titleBadgeTextColor,
          }}
        >
          {titleBadge}
        </Badge>
      )}
    </div>
  );

  const subtitleHeader = !hideSubtitle && (
    <ClampedText className={css.subtitleHeader} clamp={2}>
      {subtitle}
    </ClampedText>
  );

  const handleClick = () => {
    if (isNowPlayingDialogOpen) {
      actions.closeNowPlayingDialog();
    }

    if (!isUnifiedEventsContentReportingEnabled) {
      return;
    }

    if (!breadcrumbId) {
      actions.setBreadcrumbId();
    }
    if (
      doesContainerSupportContentReporting(guideItem.reporting?.container?.type)
    ) {
      unifiedEvents.reportContentClicked({
        ...guideItem,
        // In case with container title clicked the item(child) id should be empty.
        brazeCardId: '',
        guideId: '',
      });
    }
  };

  return (
    <div
      className={containerTitleContainerClassNames}
      data-testid="containerTitleContainer"
      data-title={title}
    >
      {hasMoreItems ? (
        <>
          <div className={css.titleContainer} data-testid="titleContainer">
            <Link
              to={linkTo}
              onClick={handleClick}
              className={classNames(
                commonCss.inkLink,
                commonCss.titleContainer,
              )}
            >
              {titleHeader}
              {subtitleHeader}
            </Link>
          </div>
          <div className={css.browseLink} data-testid="browseLink">
            <Link
              to={linkTo}
              onClick={handleClick}
              className={classNames(commonCss.inkLink, css.displayChevron)}
            >
              <i
                className={classNames(
                  chevronCss.chevronRight,
                  chevronCss.chevronRightBackground,
                  css.chevron,
                )}
              />
              <span data-testid="chevronSRText" className={css.srOnly}>
                {getLocalizedText(SEE_ALL)}
              </span>
            </Link>
            <Link
              to={linkTo}
              onClick={handleClick}
              className={commonCss.inkLink}
            >
              <span className={css.seeAll}>{getLocalizedText(SEE_ALL)}</span>
            </Link>
          </div>
        </>
      ) : (
        <div className={css.titleContainer} data-testid="titleContainer">
          {titleHeader}
          {subtitleHeader}
        </div>
      )}
      {presentation.layout === 'Locked' && (
        <div className={css.premiumLogo}>
          <PremiumLogo
            width="7"
            height="7"
            viewBox="0 0 8 8"
            fill={cssVariables['--content-area-background-color-hex']}
            guideId={guideId}
            products={guideProducts}
            image={guideImage}
            title={guideTitle}
          />
        </div>
      )}
    </div>
  );
};

ContainerTitle.propTypes = {
  containerItem: PropTypes.object.isRequired,
  getStyles: PropTypes.func,
  className: PropTypes.string,
  containerTitle: PropTypes.string,
};

ContainerTitle.defaultProps = {
  getStyles: () => ({}),
};

export default ContainerTitle;
